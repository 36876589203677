@keyframes zoom-in-fwd {
    0% {
      transform: scale(0.8);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  .zoom-in-fwd {
    animation-name: zoom-in-fwd;
    animation-timing-function: ease-out;
  }
  
  @keyframes zoom-out-bck {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(0.8);
      opacity: 0;
    }
  }
  
  .zoom-out-bck {
    animation-name: zoom-out-bck;
    animation-timing-function: ease-out;
  }