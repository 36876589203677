/* @tailwind base;
@tailwind components;
@tailwind utilities; */

html {

    margin: 0;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }