@tailwind base;
@tailwind components;
@tailwind utilities;
 
@layer base {
  :root {

    --slate-50: 210 40% 98%;
    --slate-100: 210 40% 96%;
    --slate-200: 214 32% 91%;
    --slate-300: 213 27% 84%;
    --slate-400: 215 20% 65%;
    --slate-500: 215 16% 47%;
    --slate-600: 215 19% 35%;
    --slate-700: 215 25% 27%;
    --slate-800: 217 33% 17%;
    --slate-900: 222 47% 11%;
    --slate-950: 229 84% 5%;

  

    /* 
    --indigo-50: 226 100% 97%;
    --indigo-100: 225 100% 94%;
    --indigo-200: 228 96% 89%;
    --indigo-300: 229 94% 82%;
    --indigo-400: 234 93% 74%;
    --indigo-500: 238 83% 67%;
    --indigo-600: 243 75% 59%;
    --indigo-700: 244 65% 51%;
    --indigo-800: 243 54% 41%;
    --indigo-900: 242 47% 34%;
    --indigo-950: 240 47% 21%;
    */

    --primary: 240 90% 67%;
    --primary-50: 228 107% 97%;
    --primary-100: 227 107% 94%;
    --primary-200: 230 103% 89%;
    --primary-300: 231 101% 82%;
    --primary-400: 236 100% 74%;
    --primary-500: 240 90% 67%;
    --primary-600: 245 82% 59%;
    --primary-700: 246 72% 51%;
    --primary-800: 245 63% 41%;
    --primary-900: 244 56% 34%;
    --primary-950: 242 56% 21%;



    --background: 230 30% 98%;
    --foreground: 240 10% 3.9%;
    --card: 230 30% 98%;
    --card-foreground: 240 10% 3.9%;
    --popover: 230 30% 98%;
    --popover-foreground: 240 10% 3.9%;
    --contrast: 0 0% 100%;
    --primary: 240 90% 67%;
    --primary-foreground: 0 0% 98%;
    --secondary: 240 20% 92.9%;
    --secondary-foreground: 240 5.9% 10%;
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 40%;
    --accent: 240 20% 92.9%;
    --accent-foreground: 240 5.9% 10%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 20% 90%;
    --input: 240 20% 94%;
    --ring: 237 97%, 85%;    
    --radius: 0.5rem;
    --warning: 38 92% 50%;
    --warning-foreground: 48 96% 89%;
    --color-start: #2e2e2f;
    --color-end: #222222;
  }

  .dark {

    --slate-50: 210 40% 2%;
    --slate-100: 210 40% 4%;
    --slate-200: 214 32% 9%;
    --slate-300: 213 27% 16%;
    --slate-400: 215 20% 35%;
    --slate-500: 215 16% 53%;
    --slate-600: 215 19% 65%;
    --slate-700: 215 25% 73%;
    --slate-800: 217 33% 83%;
    --slate-900: 222 47% 89%;
    --slate-950: 229 84% 95%;

    --gray-100: 0 0% 10%;
    --gray-200: 0 0% 12%;
    --gray-300: 0 0% 16%;
    --gray-400: 0 0% 18%;
    --gray-500: 0 0% 27%;
    --gray-600: 0 0% 53%;
    --gray-700: 0 0% 56%;
    --gray-800: 0 0% 49%;
    --gray-900: 0 0% 63%;
    --gray-1000: 0 0% 93%;


    --background: 240 10% 4.9%;
    --contrast: 0 0% 0%;

    --foreground: 0 0% 98%;
    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;

    --primary: 240 90% 67%;
    --primary-dark: 240° 90% 42%;
    --primary-foreground: 240 5.9% 10%;
    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;
    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 12%;
    --input: 0 0% 10%;
    --ring: 240 4.9% 83.9%;
    --radius: 0.5rem;
    --warning: 48 96% 89%;
    --warning-foreground: 38 92% 50%;
    --color-start: #cfcfcf;
    --color-end: #4c3ba1;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}